@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.LoginPage-title {
  @extend %page-title;
}

.LoginPage-mainContainer {
  display: flex;
  justify-content: center;

  width: max-content;
  margin-inline: auto;

  padding-top: 20px;
  padding-bottom: 20px;
}

.LoginPage-leftContainer {
  padding-right: 90px;
  display: flex;

  border-right: 1px solid $color-mercury;
}

.LoginPage-rightContainer {
  padding-left: 90px;

  width: 48.954%;
}

.LoginPage-socialContainer {
  margin-top: 20px;
}

@include mobile {
  .LoginPage {
    box-sizing: border-box;
    padding: $mobile-spacing;
  }

  .LoginPage-title {
    margin-top: 1px;
    margin-left: 0;
  }

  .LoginPage-mainContainer {
    width: 100%;
    flex-flow: column;

    padding-top: 0;
  }

  .LoginPage-leftContainer {
    order: 3;

    width: 100%;

    border-right: 0;
  }

  .LoginPage-rightContainer {
    order: 2;

    width: 100%;
  }

  .LoginPage-leftContainer,
  .LoginPage-rightContainer {
    padding: 0;
  }

  .LoginPage-socialContainer {
    margin-top: 0;
  }
}
