@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

$label-size: 162px;
$label-space: $label-size + 20px;
$form-size: 300px;

.LoginBox,
.SignupBox {
  @apply w-full;
}

.LoginBox-title {
  @apply mb-[20px] w-full mx-auto;

  font-size: 18px;
  font-weight: $font-weight-bold;
}

.LoginBox-form {
  @apply flex flex-col gap-md;

  max-width: $form-size;

  input {
    @apply w-full;
  }
}

.LoginBox-form-button-options-divisor {
  @apply my-md w-full flex items-center gap-sm;
}

.LoginBox-form-button-options-divisor::before,
.LoginBox-form-button-options-divisor::after {
  @apply bg-surface-container-mid w-full block h-[2px] content-[""];
}

.LoginBox-help {
  @include mobile {
    margin-bottom: $mobile-spacing;
    max-width: initial;
  }

  max-width: 290px;

  margin-top: 8px;
  color: $color-gray;
  font-size: 14px;
}

.LoginBox-strong {
  color: $color-gray-neutral;
}

.LoginBox-form-error--unique {
  @apply border-thin border-danger-default bg-danger-lighter-inverted rounded-sm p-xsm pr-md text-on-surface-2 font-2xsm-regular flex flex-col;
  @apply w-full;
}

@include mobile {
  .LoginBox-form {
    @apply gap-3xsm max-w-full;

    label {
      display: none;
    }
  }

  .LoginBox .FormGroup,
  .SignupBox .FormGroup {
    margin-bottom: 5px;
  }

  .LoginBox-title {
    margin: 0;

    font-size: 14px;
    font-weight: $font-weight-semi-bold;
  }

  .LoginBox-form-continue,
  .LoginBox-form-login-without-password {
    width: 100%;
    margin: 0;
  }

  .LoginBox-help,
  .LoginBox-redirect {
    margin-top: 5px;
    margin-left: 0 !important;
  }

  .LoginBox-form-error--unique {
    margin-top: 0;
    margin-left: 0;
  }

  .LoginBox-form-button-options-divisor {
    @apply my-3xsm;
  }
}

@include desktop {
  .LoginBox,
  .SignupBox {
    display: flex;

    flex-flow: column;
    align-items: flex-start;
  }

  .LoginBox-form .FormGroup-label {
    width: $label-size;
  }
}
