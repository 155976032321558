@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";


.BobbyProduct-link {
  display: block;

  color: $color-dark-gray;
  text-decoration: none;
}

.BobbyProduct-image {
  text-align: center;
}
.BobbyProduct-stars {
  width: 95px;
}

.BobbyProduct-title {
  margin: 10px 0;

  font-size: 16px;
  line-height: $line-height-smallest;
}

.BobbyProductTitle {
  color: $color-dark-gray;
  font-weight: $font-weight-normal;
  font-size: $font-size-marker-info;
  text-align: left;
}

.BobbyProduct-priceContainer {
  color: $color-dark-gray;
  text-align: left;
}

.BobbyProduct-price {
  float: left;

  width: 100%;
  margin-right: 20px;

  font-size: 16px;
}
.BobbyProduct-coupon {
  float: left;
  margin-top: 4px;
}
.BobbyProduct-price-link {
  font-weight: $font-weight-bold;
}
.BobbyProduct-priceFrom {
  float: left;

  width: 100%;
  margin-right: 20px;

  color: $color-dark-gray;
}

.BobbyProduct-stockStatus {
  float: left;

  width: 100%;
  margin-right: 20px;
}

@include desktop {
  .BobbyProduct {
    display: flex;

    width: 214px;
    margin: 10px 0;
    padding: 10px;

    color: $color-black;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
  }
}

@include mobile {
  .BobbyProduct {
    width: 100%;
  }

  .BobbyProduct-image {
    width: 90%;
  }
  .BobbyProduct-price-link {
    font-size: 18px;
  }
  .BobbyProduct-installment {
    color: $color-dark-gray;
    font-size: 12px;
  }
  .BobbyProductTitle {
    font-weight: $font-weight-medium;
    font-size: $font-size-link;
  }
}
