@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.Input-input {
  min-width: 0;
  padding-right: 3.5rem;
  padding-left: var(--tom-spacing-md) !important;
}

.Input-group {
  background: none !important;
  border: none;
}

.Input-input:-webkit-autofill,
.Input-input:-webkit-autofill:hover,
.Input-input:-webkit-autofill:focus,
.Input-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
