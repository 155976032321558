@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "components/Basket/Basket.scss";

.BasketPriceBox-prices {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &-value {
    display: flex;
    justify-content: flex-end;
  }
}

.BasketPriceBox-prices-title {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.BasketPriceBox-prices-values {
  color: $color-mine-shaft;
}

.BasketPriceBox-prices-values--unavailable {
  @extend .BasketPriceBox-prices-values;

  color: $color-silver;
  text-decoration: line-through;
}

.BasketPriceBox-unavailableWarning {
  padding-top: 20px;
  justify-content: right;
  color: $color-mine-shaft;
  text-align: right;
}

@include desktop {
  .BasketPriceBox-price {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .BasketPriceBox-prices {
    margin-right: 0;
  }

  .BasketContinueFloating-line-coupon {
    display: flex;
    justify-content: flex-end;
  }

  .BasketPriceBox-prices-values {
    display: inline-block;

    width: 224px;
    margin-right: 0;
  }

  .BasketPriceBox-prices-free {
    color: $color-green-free;
    font-weight: $font-weight-bold;
    font-size: 18px;
    margin-right: 50px;
  }

  .BasketPriceBox-prices-cash-to {
    display: flex;
    flex-direction: column;
    width: 235px;
  }

  .BasketPriceBox-prices-cash {
    font-size: 20px;
    font-weight: $font-weight-bold;
  }

  .BasketPriceBox-prices-to {
    font-weight: $font-weight-normal;
    font-size: 20px;
  }

  .BasketPriceBox-prices-delivery {
    font-weight: $font-weight-medium;
    font-size: 18px;
    width: 235px;
  }

  .BasketPriceBox-prices-withoutDiscount {
    font-weight: $font-weight-medium;
    font-size: 18px;
    width: 235px;
  }

  .BasketPriceBox-prices-titleTotal {
    font-size: 18px;
    margin-right: 24px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .BasketPriceBox-prices-titleProducts {
    font-weight: $font-weight-medium;
    font-size: 18px;
    margin-right: 24px;
  }

  .BasketPriceBox-prices-titleBold {
    font-weight: $font-weight-bold;
    font-size: 20px;
    margin-right: 24px;
  }
}

@include mobile {
  .BasketPriceBox-prices {
    font-size: 14px;
    flex-wrap: wrap;
  }

  .BasketPriceBox-price {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin: 16px 16px 8px 16px;
  }

  .BasketContinueFloating-line {
    display: flex;
    justify-content: flex-end;
  }

  .BasketContinueFloating-line-coupon {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  .BasketPriceBox-prices-title {
    width: initial;
    text-align: left;
    margin-left: 16px;
  }

  .BasketPriceBox-prices-values {
    margin-right: 20px;

    text-align: right;
  }

  .BasketPriceBox-prices-titleTotal {
    font-size: 14px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 5px;
  }

  .BasketPriceBox-prices-titleProducts {
    font-weight: $font-weight-medium;
    font-size: 14px;
  }

  .BasketPriceBox-prices-titleBold {
    font-weight: $font-weight-bold;
    font-size: 16px;
  }

  .BasketPriceBox-unavailableWarning {
    box-sizing: border-box;
    padding: 16px 16px 0 16px;
    background-color: $color-wild-sand;
    text-align: center;
    width: 100%;
  }

  .BasketPriceBox-prices-cash-to {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .BasketPriceBox-prices-cash {
    font-size: 16px;
    font-weight: $font-weight-bold;
  }

  .BasketPriceBox-prices-to {
    font-weight: $font-weight-normal;
    font-size: 16px;
    margin-left: 28px;
  }

  .BasketPriceBox-prices-delivery {
    font-weight: $font-weight-medium;
    font-size: 14px;
  }

  .BasketPriceBox-prices-free {
    color: $color-green-free;
    font-weight: $font-weight-bold;
    font-size: 14px;
  }

  .BasketPriceBox-prices-withoutDiscount {
    font-weight: $font-weight-medium;
    font-size: 14px;
  }
}
